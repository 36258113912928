/** @jsx jsx */
import { jsx } from 'theme-ui'
import { Grid, Box, Button, Alert } from '@theme-ui/components'
import { Link } from 'gatsby'
import Layout from './layout'

// @ts-ignore
import Home2 from '../texts/home2.mdx'
// @ts-ignore
import Home3 from '../texts/home3.mdx'
// @ts-ignore
import Home4 from '../texts/home4.mdx'
// @ts-ignore
import Home5JGS from '../texts/home5-jgs.mdx'
// @ts-ignore
import Home6JGA from '../texts/home6-jga.mdx'
// @ts-ignore
import Home7Other from '../texts/home7-other'

import Title from './title'
import Listing from './listing'
import List from './list'
import useMinimalBlogConfig from '../hooks/use-minimal-blog-config'
import replaceSlashes from '../utils/replaceSlashes'

type Props = {
  pageContext
}

const Homepage = ({ posts }) => {
  const { basePath, blogPath } = useMinimalBlogConfig()

  // console.log('Number of posts in homepage component:', Object.keys(posts).length)

  return (
    <Layout>
      <section
        aria-label='Homepage Containers up to Posts'
        sx={{
          mb: [3, 4, 5],
          p: {
            fontSize: [1, 2, 3],
            mt: 2
          },
          textAlign: `center`,
          iframe: {
            objectFit: 'contain',
            width: '100%',
            height: ['360px', '430px', '540px']
          },
          img: {
            objectFit: 'cover',
            objectPosition: '50% 30%',
            borderRadius: '5px',
            width: '100%',
            height: 'auto'
          }
        }}>
        <section
          aria-label='Banner'
          sx={{
            '& > div': {
              position: 'relative',
              width: '100%'
            },
            mb: [3, 4, 5]
          }}>
          <div>
            <img src='./hero-book-drz.jpg'></img>
            <Alert variant='home'>Ride with Jay</Alert>
            <a href='https://amzn.to/2OngHEK' target='_blank'>
              <Button variant='home'>Get the book</Button>
            </a>
            {/* <a href="https://amzn.to/2pq9Vph" target="_blank">
              <Button variant='home2'>Get the book in India</Button>
            </a> */}
          </div>
        </section>

        <section
          aria-label='Intro Panel and Quint Video'
          sx={{
            img: {
              objectPosition: '20% 50%',
              height: '100%'
            },
            mb: [3, 4, 5],
            padding: '3%',
            borderRadius: '5px',
            border: '1px solid #333'
          }}>
          <Grid
            aria-label='Intro Panel'
            gap={4}
            columns={[1, 1, 2]}
            sx={{
              mb: [3, 4, 5]
            }}>
            <Box>
              <img src='./images/taglangla.jpg'></img>
            </Box>
            <Box
              sx={{
                textAlign: `left`,
                borderWidth: '1px',
                borderColor: 'white'
              }}>
              <Home2 />
            </Box>
          </Grid>
          <Home3 aria-label='Quint Video' />
        </section>

        {/* <section
          aria-label="Press Coverage"
          sx={{
            padding: '3%',
            borderRadius: '5px',
            border: '1px solid #333',
            mb: [3, 4, 5],
          }}
        >
          Press
        </section> */}

        <section
          aria-label='About Jay'
          sx={{
            padding: '3%',
            borderRadius: '5px',
            border: '1px solid #333',
            mb: [3, 4, 5]
          }}>
          <Home4 />
        </section>

        <section
          aria-label='Jammin thru the Global South'
          sx={{
            padding: '3%',
            borderRadius: '5px',
            border: '1px solid #333',
            mb: [3, 4, 5]
          }}>
          <Home5JGS />
        </section>

        <section
          aria-label='Jammin Global Adventures'
          sx={{
            padding: '3%',
            borderRadius: '5px',
            border: '1px solid #333',
            mb: [3, 4, 5]
          }}>
          <Home6JGA />
        </section>
      </section>

      <section aria-label='Posts'>
        <Title text='Latest Posts'>
          <Link to={replaceSlashes(`/${basePath}/${blogPath}`)}>
            Read all posts
          </Link>
        </Title>

        <Listing
          posts={posts}
          sx={{
            mb: [4, 5, 6]
          }}
        />
      </section>

      <section
        aria-label='Other Projects'
        sx={{
          padding: '3%',
          borderRadius: '5px',
          border: '1px solid #333'
        }}>
        <List>
          <Home7Other />
        </List>
      </section>
    </Layout>
  )
}

export default ({ pageContext }: Props) => {
  const { posts } = pageContext
  return <Homepage posts={posts} />
}

// export default () => {
//   return <Homepage />
// }

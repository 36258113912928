import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Ride with Jammin Global Adventures`}</h2>
    <p>{`Does the thought of riding a motorcycle across high mountains and dense forests in far-off lands excite you? But are you a bit unsure about how to go about full-filling this dream? A Jammin Global Adventure, led by Jay Kannaiyan, will provide you with a bite-sized adventure on a motorcycle across stunning landscapes in a safe and professional manner.`}</p>
    <p>{`Jay has the experience of traveling across five continents and has a wide network of local contacts in all the countries that he leads tours in. His calm, collected and organized attitude ensures that there are no ordeals, only adventures. Ride with Jay and let the adventure add richness to your life!`}</p>
    <iframe width="560" src="https://www.youtube-nocookie.com/embed/v5Qs4g8Ac_g" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      
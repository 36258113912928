import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Jammin thru the Global South`}</h2>
    <p>{`Jammin thru the Global South is the name of Jay’s journey that saw him ride sanDRina, his Suzuki DR650 motorcycle from Chicago to New Delhi overland through the Americas, Europe, Africa and into India from March 2010 till June 2013 with breaks along the way, including an extended one in Kenya. `}</p>
    <p>{`Jay has posted a rich, detailed account of his journey and now that content can be found in `}<a parentName="p" {...{
        "href": "/tags/background"
      }}>{`the blog`}</a>{`. `}</p>
    <p>{`Start at the `}<a parentName="p" {...{
        "href": "/tags/background"
      }}>{`beginning of the journey`}</a>{` or jump ahead to these highlights: `}<a parentName="p" {...{
        "href": "/tags/stahlratte"
      }}>{`Sailing on the Stahlratte`}</a>{`, `}<a parentName="p" {...{
        "href": "/tags/bolivia"
      }}>{`Bolivia`}</a>{`, `}<a parentName="p" {...{
        "href": "/tags/patagonia"
      }}>{`Patagonia`}</a>{`, `}<a parentName="p" {...{
        "href": "/tags/brazil"
      }}>{`Brazil`}</a>{`, `}<a parentName="p" {...{
        "href": "/tags/grimaldi"
      }}>{`Atlantic Crossing on a Cargo Ship`}</a>{`, `}<a parentName="p" {...{
        "href": "/tags/europe"
      }}>{`Europe`}</a>{`, `}<a parentName="p" {...{
        "href": "/tags/egypt"
      }}>{`Egypt`}</a>{`, `}<a parentName="p" {...{
        "href": "/tags/kenya"
      }}>{`Kenya`}</a>{`, or `}<a parentName="p" {...{
        "href": "/tags/mozambique"
      }}>{`Mozambique`}</a>{`.`}</p>
    <p><a parentName="p" {...{
        "href": "https://bit.ly/rtw_map",
        "target": "_blank",
        "rel": "noopener noreferrer"
      }}><img parentName="a" {...{
          "src": "./images/jgs-map.jpg",
          "alt": null
        }}></img></a>{`
Jammin thru the Global South Route Map`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      
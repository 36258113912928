import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <iframe width="560" src="https://www.youtube-nocookie.com/embed/U5BVAEq6T-k" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
    <p>{`A summary of Jay’s journey from the US to India, put together by `}<a parentName="p" {...{
        "href": "https://www.thequint.com/voices/blogs/how-i-quit-my-job-and-took-a-three-year-bike-journey-back-to-india",
        "target": "_blank",
        "rel": "noopener noreferrer"
      }}>{`The Quint`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      
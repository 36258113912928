import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Motorcycle Adventurer `}<a parentName="p" {...{
        "href": "https://jaykannaiyan.com/",
        "target": "_blank",
        "rel": "noopener noreferrer"
      }}>{`Jay Kannaiyan`}</a>{` leads exclusive, small group motorcycle adventures in the most exciting parts of the world that he’s `}<a parentName="p" {...{
        "href": "http://overdrive.in/news-cars-auto/features/jay-kannaiyans-ride-around-the-world-on-a-motorcycle/",
        "target": "_blank",
        "rel": "noopener noreferrer"
      }}>{`ridden through`}</a>{` and more. Explore the site and get in touch to plan your trip.`}</p>
    <p>{`Jay’s book “Riding Towards Me” has been published! `}<a parentName="p" {...{
        "href": "https://amzn.to/2pq9Vph",
        "target": "_blank",
        "rel": "noopener noreferrer"
      }}>{`Get it in India here`}</a>{` and in the `}<a parentName="p" {...{
        "href": "https://amzn.to/2OngHEK",
        "target": "_blank",
        "rel": "noopener noreferrer"
      }}>{`US here`}</a>{`. For other countries, get it from the Amazon US site.`}</p>
    <p>{`Check out the `}<a parentName="p" {...{
        "href": "/tags/background"
      }}>{`travel blog`}</a>{` on Jay’s 3 year motorcycle journey from the US to India.`}</p>
    <p>{`Dive deeper into the site with `}<a parentName="p" {...{
        "href": "/tags/published"
      }}>{`articles Jay has written`}</a>{`, `}<a parentName="p" {...{
        "href": "/tags/press"
      }}>{`press coverage`}</a>{`, `}<a parentName="p" {...{
        "href": "/tags/video"
      }}>{`videos`}</a>{`, and `}<a parentName="p" {...{
        "href": "/tags/photo-gallery"
      }}>{`photos`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      
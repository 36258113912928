import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Title = makeShortcode("Title");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Title text="Jay's Other Projects" mdxType="Title" />
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://heartofthemattershow.com/",
          "target": "_blank",
          "rel": "noopener noreferrer"
        }}>{`Heart of the Matter Podcast`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://jaykannaiyan.com",
          "target": "_blank",
          "rel": "noopener noreferrer"
        }}>{`Jay Kannaiyan.com`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://mtmgrid.in/",
          "target": "_blank",
          "rel": "noopener noreferrer"
        }}>{`Motorcycle Travellers Meet`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      
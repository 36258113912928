import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`About Jay`}</h2>
    <p>{`Jay Kannaiyan is an adventurer turned coder. He loves exploring our world on two wheels and building products and services to improve peoples’ lives. Along the way he’s been a product design engineer, entrepreneur in the `}<a parentName="p" {...{
        "href": "http://overdrive.in/features/jay-kannaiyans-ride-around-the-world-on-a-motorcycle/",
        "target": "_blank",
        "rel": "noopener noreferrer"
      }}>{`adventure travel`}</a>{` and `}<a parentName="p" {...{
        "href": "https://money.cnn.com/2016/02/17/news/economy/india-new-delhi-air-pollution/index.html",
        "target": "_blank",
        "rel": "noopener noreferrer"
      }}>{`air quality`}</a>{` space, and now a full stack `}<a parentName="p" {...{
        "href": "https://www.linkedin.com/in/jaykannaiyan/",
        "target": "_blank",
        "rel": "noopener noreferrer"
      }}>{`software engineer`}</a>{`. He cares deeply about building community, nurturing idle time, and sharing good food. Have you heard of his `}<a parentName="p" {...{
        "href": "https://www.youtube.com/watch?v=L5ae0-JwDl8",
        "target": "_blank",
        "rel": "noopener noreferrer"
      }}>{`chicken curry`}</a>{`?`}</p>
    <iframe width="560" src="https://www.youtube-nocookie.com/embed/L5ae0-JwDl8" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
TEDx Talk on Eudaimonia and Travel, New Delhi, 2016
    <p>{`In 2010, Jay dropped everything he had in the US to ride his motorcycle back home to India by the longest possible route. The journey took him 3 years and 3 months as he rode through 33 countries across Latin America, Europe, and Africa, before finally reaching India in 2013. Jay and his motorcycle, sanDRina, encountered mechanical meltdowns, remote Mayan villages, weeks of high-altitude desert isolation, Caribbean and Atlantic voyages, humility, and friendship. His go-with-the-flow attitude mixed with a whatever-it-takes resolve deliver a story of global trails and an adventuring insight that brought him fame amongst the overland traveling community before his journey was even complete. The book about the journey has just been published. Get your copy on Amazon (`}<a parentName="p" {...{
        "href": "https://amzn.to/2pq9Vph",
        "target": "_blank",
        "rel": "noopener noreferrer"
      }}>{`in India`}</a>{`, and `}<a parentName="p" {...{
        "href": "https://amzn.to/2OngHEK",
        "target": "_blank",
        "rel": "noopener noreferrer"
      }}>{`in the US`}</a>{`). Jay uses his varied experiences to inspire others to travel, explore, and discover how each one of us can contribute to a better world.`}</p>
    <iframe width="560" src="https://www.youtube-nocookie.com/embed/lJn-lwaUJSk" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
Motorcycle Travellers Meet Interview, Bangalore, 2013

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      